html {
  position: relative;
  min-height: 100%;
}

body {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

a,
.btn-link {
  color: #0366d6;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.app {
  /*position: relative;*/
  min-height: 100%;
  min-width: 100%;
}

#root {
  min-height: 100%;
  min-width: 100%;
  padding: 24px;
}

.top-row {
  height: 5.5rem;
  padding-top: 40px;
  display: flex;
  align-items: center;
}

.main {
  flex: 1;
}

.main .top-row {
  background-color: #f7f7f7;
  border-bottom: 1px solid #d6d5d5;
  justify-content: flex-end;
}

.main .top-row > a,
.main .top-row .btn-link {
  white-space: nowrap;
  margin-left: 1.5rem;
}

.main .top-row a:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mapSidebarButton {
  background-color: white;
}
.mapSidebarButton:hover {
  background-color: black;
  color: white;
}

#pullSidebarButton {
  background-color: white;
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 1;
}
#pullSidebarButton:hover {
  background-color: black;
  color: white;
}

#syncSidebarButton {
  background-color: white;
  position: absolute;
  top: 10px;
  left: 90px;
  z-index: 1;
}

#syncSidebarButton:hover {
  background-color: black;
  color: white;
}

#sidebarInnerButton {
}
#outerSidebarButton {
  background-color: white;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
#outerSidebarButton:hover {
  background-color: black;
  color: white;
}

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: -350px;
  background-color: #373d43;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidebar .top-row {
  background-color: rgba(0, 0, 0, 0.4);
}

.sidebar .navbar-brand {
  font-size: 1.1rem;
}

.sidebar .oi {
  width: 2rem;
  font-size: 1.1rem;
  vertical-align: text-top;
  top: -2px;
}

.sidebar .nav-item {
  font-size: 0.9rem;
}

.sidebar .nav-item:first-of-type {
  padding-top: 1rem;
}

.sidebar .nav-item:last-of-type {
  padding-bottom: 1rem;
}

.sidebar .nav-item a {
  color: #d7d7d7;
  border-radius: 4px;
  height: 3rem;
  display: flex;
  align-items: center;
  line-height: 3rem;
}

.sidebar .nav-item a.active {
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
}

.sidebar .nav-item a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.content {
  padding-top: 1.1rem;
}

.navbar-toggler {
  background-color: rgba(255, 255, 255, 0.1);
}

.valid.modified:not([type="checkbox"]) {
  outline: 1px solid #26b050;
}

.invalid {
  outline: 1px solid red;
}

.validation-message {
  color: red;
}

#blazor-error-ui {
  background: lightyellow;
  bottom: 0;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
  display: none;
  left: 0;
  padding: 0.6rem 1.25rem 0.7rem 1.25rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

#blazor-error-ui .dismiss {
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
}

@media (max-width: 10) {
  .main .top-row:not(.auth) {
    display: none;
  }

  .main .top-row.auth {
    justify-content: space-between;
  }

  .main .top-row a,
  .main .top-row .btn-link {
    margin-left: 0;
  }
}

/*@media (min-width: 768px) {
    app {
        flex-direction: row;
    }

    .sidebar {
        width: 250px;
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .main .top-row {
        position: sticky;
        top: 0;
    }

    .main > div {
        padding-left: 2rem !important;
        padding-right: 1.5rem !important;
    }

    .navbar-toggler {
        display: none;
    }

    .sidebar .collapse {*/
/* Never collapse the sidebar for wide screens */
/*display: block;
    }
}*/
.dropdown-container {
  transition: 0.5s;
  background-color: white;
  border: solid;
  border-width: 0px;
  width: 100%;
  bottom: -350px;
  position: fixed;
  z-index: 1001;
  left: 0;
  transition-timing-function: ease;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 35px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
.newcard {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  padding: 5px;
}

.newcard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.measurementInfo {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
}

.pulseIcon {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: blue;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 204, 255, 0.4);
  animation: pulse 3s infinite;
}

.pulseIcon:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
  }
}

.stickyHeader {
  position: sticky;
  top: 0;
  background: white;
  border: 0.5px solid black;
}

.buttonPulse {
  animation: pulse 1.5s infinite;
}

.bottomNav {
  background-color: #373d43;
  border: 1px solid #eaeaea;
  color: #eaeaea;
}

.bottomNav:hover {
  background-color: #eaeaea;
  color: #373d43;
}

.bottomNavActive {
  background-color: white;
  color: #373d43;
}

.listCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  padding: 5px;
  background-color: #eaeaea;
}

.listCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.hiddenInfoCard {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: height 1s 1s, opacity 0.5s 0.1s;
}

.showmeInfoCard {
  max-height: 5000px;
  opacity: 1;
  transition: height 1s 0.5s, opacity 0.2s 0s;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.showSideNav {
  left: 0;
}

.hideSideNav {
  left: -400px;
}

#myMap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}
#myConfirm {
  z-index: 2000;
}

.hideMap {
  z-index: -1;
  opacity: 0;
  transition: 0.3s;
}

.defaultsContainer {
  min-height: 255px;
}

.hideContainer {
  opacity: 0;
  max-height: 0;
  transition: 0.5s;
}
.showContainer {
  opacity: 1;
  max-height: 400px;
  transition: 0.5s;
}

.xxlModal {
  max-width: 95%;
}

.selectAnimalTableHeader {
  font-size: 14px;
  overflow: hidden;
}
.cursorPointer {
  cursor: pointer;
}

.top-right.subcontrol-container {
  top: 50px;
}

.colorSquare {
  width: 16px;
  height: 16px;
  text-align: center;
}
